<app-page>
  <page-header></page-header>
  <page-content>
    <app-hero pageTitle="غايات التعليم 2030" heroImage="assets/images/goals-hero.png"></app-hero>

    <div id="goals" class="my-5">
      <div class="container px-xl-8">
        <div class="row">
          <div *ngFor="let goal of goals" class="col-xl-4 col-lg-4 col-md-6 mb-5">
            <div class="goals-card" style="height: 320px">
              <div
                class="card h-100 rounded-3 border-transparent hover-border-primary overflow-hidden position-relative bg-primary bg-opacity-10"
              >
                <a class="card-body p-4" style="text-align: justify; font-size: 16px" [href]="goal.url" target="_blank">
                  <img src="assets/images/purpose.png" alt="purpose" class="mb-2" />
                  <div class="card-title text-dark fw-bold opacity-75 h5">{{ goal.name }}</div>
                  <p class="text-dark mb-0 ellipse-10">
                    {{ goal.description }}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
