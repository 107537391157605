import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";

import {ActivityComponent} from "./components/pages/activities/activities.component";
import {Education21Component} from "./components/pages/education21/education21.component";
import {GoalsComponent} from "./components/pages/goals/goals.component";

import {LandingPageComponent} from "./components/pages/landing-page/landing-page.component";
import {InternationalReportsComponent} from "./components/pages/global-and-international-reports/global-and-international-reports.component";
import {VideoComponent} from "./components/pages/video/video.component";
import {ActivitiesPageResolver} from "./resolvers/activities-page.resolver";
import {Education21PageResolver} from "./resolvers/education21-page.resolver";
import {GoalsPageResolver} from "./resolvers/goals-page.resolver";
import {LandingPageResolver} from "./resolvers/landing-page.resolver";
import {InternationalReportsPageResolver} from "./resolvers/global-and-international-reports-page.resolver";
import {VideosPageResolver} from "./resolvers/videos-page.resolver";
import {SingleActivityComponent} from "./components/pages/activities/single-activity/single-activity.component";
import {SingleActivityPageResolver} from "./resolvers/single-activity-page.resolver";

const routes: Routes = [
  {path: "", component: LandingPageComponent, resolve: {page: LandingPageResolver}},
  {path: "goals", component: GoalsComponent, resolve: {page: GoalsPageResolver}},
  {path: "activities", component: ActivityComponent, resolve: {page: ActivitiesPageResolver}},
  {path: "activities/:activityId", component: SingleActivityComponent, resolve: {page: SingleActivityPageResolver}},
  {path: "education21", component: Education21Component, resolve: {page: Education21PageResolver}},
  {
    path: "reports",
    component: InternationalReportsComponent,
    resolve: {page: InternationalReportsPageResolver},
  },
  {path: "videos", component: VideoComponent, resolve: {page: VideosPageResolver}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: "enabled", onSameUrlNavigation: "reload"})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
