<app-page>
  <page-header></page-header>
  <page-content>
    <app-hero pageTitle="أنشطة وفعاليات التعليم 2030 " heroImage="assets/images/edu-activity-hero.png"></app-hero>
    <div id="education-activity" class="my-5">
      <div class="container">
        <select
          (change)="switchType($event)"
          style="max-width: 300px"
          class="form-select mb-4 bg-very-light-gray bg-opacity-50 border-0"
        >
          <option value="">الكل</option>
          <option *ngFor="let activitiesType of activitiesTypes" [value]="activitiesType">
            {{ activitiesType }}
          </option>
        </select>

        <div class="row">
          <div *ngFor="let activity of activities; index as i" class="col-xl-4 col-lg-6 col-sm-6 mb-5">
            <div class="item" [attr.data-sub-html]="activity.name">
              <div class="education-activity-card">
                <div class="card overflow-hidden position-relative">
                  <img
                    class="img-fluid d-block w-100"
                    style="height: 400px"
                    [src]="activity.image | imageLink"
                    [alt]="activity.image.title"
                    routerLink="/activities/{{ activity.id }}"
                  />

                  <span class="activity-type text-white fw-bold bg-primary position-absolute px-3 py-2">
                    {{ activity.type.name }}
                  </span>
                </div>
                <h6 class="text-center text-dark fw-bold mt-3 mb-0 small ellipse-3">
                  {{ activity.name }}
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <nav *ngIf="paginationButtonsNumber > 0">
            <ul class="pagination justify-content-center dark">
              <li
                *ngFor="let item of [].constructor(paginationButtonsNumber); index as i"
                (click)="showItemsByPage(i + 1)"
                class="page-item"
                [ngClass]="{active: page === i}"
              >
                <a class="page-link">{{ i + 1 }}</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <div *ngIf="activities.length === 0" class="no-data">لا توجد أنشطة</div>
  </page-content>
  <page-footer></page-footer>
</app-page>
