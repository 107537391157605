import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {GET_SINGLE_ACTIVITY_PAGE} from "../queries/single-activity.query";
import {Activity} from "../interfaces/landing-page";

@Injectable({
  providedIn: "root",
})
export class SingleActivityPageResolver implements Resolve<Activity> {
  constructor(private apollo: Apollo) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Activity> {
    return this.apollo
      .query({
        query: GET_SINGLE_ACTIVITY_PAGE,
        variables: {
          activityId: route.params["activityId"],
        },
      })
      .pipe(
        map((result): Activity => {
          const {activity} = result.data;
          return activity;
        }),
      );
  }
}
