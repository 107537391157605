import {gql} from "apollo-angular";
import {Activity} from "../interfaces/landing-page";

export const GET_SINGLE_ACTIVITY_PAGE = gql<{activity: Activity}, {activityId: string}>`
  query ($activityId: ID!) {
    activity: edu2030_office_activities_by_id(id: $activityId) {
      id
      name
      description
      date
      type {
        id
        name
      }
      image {
        id
        filename: filename_download
        title
      }
      images {
        id
        image {
          id
          filename: filename_download
          title
        }
        title
        description
      }

      videos {
        id
        url
        title
        description
      }

      files {
        id
        file {
          id
          filename_download
          title
          type
          filesize
        }
        title
        description
      }
    }
  }
`;
