import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Activity} from "src/app/interfaces/landing-page";

@Component({
  selector: "app-single-activity",
  templateUrl: "./single-activity.component.html",
  styleUrls: ["./single-activity.component.scss"],
})
export class SingleActivityComponent implements OnInit {
  activity!: Activity;
  constructor(private route: ActivatedRoute) {}
  ngOnInit() {
    this.activity = this.route.snapshot.data["page"] as Activity;
  }

  getThumbnailURL(url: string): string {
    const normalizedURL = new URL(url);
    if (["www.youtube.com", "youtube.com", "youtu.be"].indexOf(normalizedURL.host) !== -1) {
      let videoId: string | undefined;
      const v = normalizedURL.searchParams.get("v");
      if (v && v.match(/^[\w-]{10,12}$/)) {
        videoId = v;
      } else {
        const match = /^(?:\/embed)?\/([\w-]{10,12})$/.exec(normalizedURL.pathname);
        if (match) {
          videoId = match[1];
        }
      }

      if (videoId) {
        return `http://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      }
    }
    return "http://img.youtube.com/vi/0/hqdefault.jpg";
  }
}
