<app-page>
  <page-header></page-header>
  <page-content>
    <app-hero [pageTitle]="activity.name" heroImage="assets/images/education21-hero.png"></app-hero>

    <div id="publications" class="mt-3" style="min-height: 500px">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mt-lg-0">
            <div class="card rounded-0">
              <div class="card-body">
                <div class="d-flex gap-3 flex-column flex-md-row mb-5">
                  <div style="width: 300px" class="card rounded-0 p-2">
                    <img
                      [src]="activity.image | imageLink"
                      class="book-img img-fluid contain center d-block mx-auto"
                      [alt]="activity.name"
                    />
                  </div>

                  <div class="flex-grow-1 pt-4">
                    <h3 class="text-primary my-3">{{ activity.name }}</h3>
                    <p *ngIf="activity.date" class="fw-light mt-3">
                      <ng-container>سنة النشر</ng-container>
                      {{ activity.date }}
                    </p>
                  </div>
                </div>

                <ul class="nav nav-pills" id="" role="tablist">
                  <li *ngIf="activity.description" class="nav-item mx-2" role="presentation">
                    <button
                      class="nav-link active px-4"
                      id="tab0-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab0-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="tab0-tab-pane"
                      aria-selected="false"
                    >
                      <!-- <fa-icon [icon]="faEllipsisVertical" class="me-2"></fa-icon> -->
                      <ng-container>التفاصيل</ng-container>
                    </button>
                  </li>

                  <li *ngIf="activity.images.length > 0" class="nav-item mx-2" role="presentation">
                    <button
                      class="nav-link px-4"
                      id="tab1-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab1-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="tab1-tab-pane"
                      aria-selected="false"
                    >
                      <!-- <fa-icon [icon]="faEllipsisVertical" class="me-2"></fa-icon> -->
                      <ng-container>الصور</ng-container>
                    </button>
                  </li>

                  <li *ngIf="activity.videos.length > 0" class="nav-item mx-2" role="presentation">
                    <button
                      class="nav-link px-4"
                      id="tab2-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab2-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="tab2-tab-pane"
                      aria-selected="false"
                    >
                      <!-- <fa-icon [icon]="faEllipsisVertical" class="me-2"></fa-icon> -->
                      <ng-container>الفيديو</ng-container>
                    </button>
                  </li>
                  <li *ngIf="activity.files.length > 0" class="nav-item mx-2" role="presentation">
                    <button
                      class="nav-link px-4"
                      id="tab3-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab3-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="tab3-tab-pane"
                      aria-selected="false"
                    >
                      <!-- <fa-icon [icon]="faEllipsisVertical" class="me-2"></fa-icon> -->
                      <ng-container>المرفقات</ng-container>
                    </button>
                  </li>
                </ul>
                <hr class="my-3 border-very-light-gray" />
                <div class="tab-content" id="manager-tabs">
                  <div
                    *ngIf="activity.description"
                    class="tab-pane active fade show"
                    id="tab0-tab-pane"
                    role="tabpane0"
                    aria-labelledby="tab0-tab"
                    tabindex="0"
                  >
                    <div class="row">
                      <div class="col">
                        <p *ngIf="activity.description"></p>
                        <div class="mb-5 d-flex justify-content-center mt-5">
                          <!-- <youtube-player></youtube-player> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="activity.images"
                    class="tab-pane fade show"
                    id="tab1-tab-pane"
                    role="tabpane1"
                    aria-labelledby="tab1-tab"
                    tabindex="1"
                  >
                    <div class="row">
                      <div *ngFor="let activityImage of activity.images; index as i" class="col-xl-4 col-lg-6 col-sm-6 mb-5">
                        <div class="item">
                          <div class="education-activity-card">
                            <div class="card overflow-hidden position-relative">
                              <img
                                class="img-fluid d-block w-100"
                                style="height: 300px"
                                [src]="activityImage.image | imageLink"
                                [alt]="activityImage.image.title"
                              />
                            </div>
                            <h3 class="text-center text-dark fw-bold mt-3 mb-0 small ellipse-3">
                              {{ activityImage.title }}
                            </h3>
                            <h6 class="text-center text-dark fw-bold mt-3 mb-0 small ellipse-3">
                              {{ activityImage.description }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="activity.videos"
                    class="tab-pane fade show"
                    id="tab2-tab-pane"
                    role="tabpane1"
                    aria-labelledby="tab2-tab"
                    tabindex="1"
                  >
                    <div class="row">
                      <div *ngFor="let activityVideo of activity.videos; index as i" class="col-xl-4 col-lg-6 col-sm-6 mb-5">
                        <div class="item">
                          <div class="education-activity-card">
                            <div class="card overflow-hidden position-relative">
                              <!-- to add video -->

                              <div
                                class="item"
                                [attr.data-src]="activityVideo.url"
                                [attr.data-sub-html]="activityVideo.title"
                              >
                                <div class="video-card">
                                  <div class="card rounded-0 position-relative thumbnail-container">
                                    <img
                                      class="img-fluid contain d-block w-100 thumbnail-image"
                                      [src]="getThumbnailURL(activityVideo.url)"
                                      [alt]="activityVideo.title"
                                    />
                                    <img src="assets/images/youtube-icon.png" alt="youtube-icon" class="youtube-icon" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <h3 class="text-center text-dark fw-bold mt-3 mb-0 small ellipse-3">
                              {{ activityVideo.title }}
                            </h3>
                            <h6 class="text-center text-dark fw-bold mt-3 mb-0 small ellipse-3">
                              {{ activityVideo.description }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="activity.files"
                    class="tab-pane fade show"
                    id="tab3-tab-pane"
                    role="tabpane1"
                    aria-labelledby="tab3-tab"
                    tabindex="1"
                  >
                    <div class="row">
                      <ul class="list-group">
                        <li class="list-group-item" *ngFor="let activityFile of activity.files">
                          <h5>{{ activityFile.title }}</h5>
                          <p>
                            {{ activityFile.description }}
                          </p>
                          <a [href]="activityFile.file | fileLink" class="btn btn-primary">تنزيل</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
