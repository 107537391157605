import {environment} from "src/environments/environment";

import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {registerLocaleData} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import ar from "@angular/common/locales/ar";

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";

import {MatDateFnsModule, DateFnsAdapter} from "@angular/material-date-fns-adapter";

import {arEG} from "date-fns/locale";

import {ApolloModule, APOLLO_OPTIONS} from "apollo-angular";
import {HttpLink} from "apollo-angular/http";
import {InMemoryCache} from "@apollo/client/core";

import {InfiniteScrollModule} from "ngx-infinite-scroll";

import {AppRoutingModule} from "./app-routing.module";

import {FlickityDirective} from "./directives/flickity.directive";
import {SlickItemDirective} from "./directives/slick-item.directive";
import {CounterDirective} from "./directives/counter.directive";

import {ImageLinkPipe} from "./pipes/image-link.pipe";
import {FileLinkPipe} from "./pipes/file-link.pipe";
import {FileTypePipe} from "./pipes/file-type.pipe";

import {AppComponent} from "./app.component";
import {FooterLinksComponent} from "./components/shared/footer-links/footer-links.component";
import {SlickCarouselComponent} from "./components/shared/slick-carousel/slick-carousel.component";
import {LoaderComponent} from "./components/shared/loader/loader.component";
import {PageComponent} from "./components/shared/page/page.component";
import {PageContentComponent} from "./components/shared/page/page-content/page-content.component";
import {PageHeaderComponent} from "./components/shared/page/page-header/page-header.component";
import {PageFooterComponent} from "./components/shared/page/page-footer/page-footer.component";
import {NavbarComponent} from "./components/shared/navbar/navbar.component";
import {HeroComponent} from "./components/shared/hero/hero.component";
import {BreadcrumbComponent} from "./components/shared/breadcrumb/breadcrumb.component";

import {AppYoutubeZoomComponent} from "./components/shared/app-youtube-zoom/app-youtube-zoom.component";

import {LandingPageComponent} from "./components/pages/landing-page/landing-page.component";

import {YouTubePlayerModule} from "@angular/youtube-player";
import {LandingHeroComponent} from "./components/pages/landing-page/landing-hero/landing-hero.component";
import {LandingGoalsComponent} from "./components/pages/landing-page/landing-goals/landing-goals.component";
import {LandingActivitiesComponent} from "./components/pages/landing-page/landing-activities/landing-activities.component";
import {LandingEducation21Component} from "./components/pages/landing-page/landing-education21/landing-education21.component";
import {LandingVideoComponent} from "./components/pages/landing-page/landing-video/landing-video.component";
import {LandingInternationalReportsComponent} from "./components/pages/landing-page/landing-reports/landing-reports.component";
import {LandingChanelsComponent} from "./components/pages/landing-page/landing-chanels/landing-chanels.component";
import {GoalsComponent} from "./components/pages/goals/goals.component";
import {ActivityComponent} from "./components/pages/activities/activities.component";
import {Education21Component} from "./components/pages/education21/education21.component";
import {InternationalReportsComponent} from "./components/pages/global-and-international-reports/global-and-international-reports.component";
import {VideoComponent} from "./components/pages/video/video.component";
import { SingleActivityComponent } from './components/pages/activities/single-activity/single-activity.component';

const angular = [BrowserModule, BrowserAnimationsModule, HttpClientModule, FormsModule, ReactiveFormsModule];

const material = [
  MatDatepickerModule,
  MatDateFnsModule,
  MatInputModule,
  MatFormFieldModule,
  MatIconModule,
  MatButtonModule,
  YouTubePlayerModule,
  MatDialogModule,
];

const thirdParties = [ApolloModule, InfiniteScrollModule];

const directives = [FlickityDirective, SlickItemDirective, CounterDirective];

const pipes = [ImageLinkPipe, FileLinkPipe, FileTypePipe];

const components = [
  AppComponent,
  FooterLinksComponent,
  SlickCarouselComponent,
  LoaderComponent,
  PageComponent,
  PageContentComponent,
  PageHeaderComponent,
  PageFooterComponent,
  NavbarComponent,
  HeroComponent,
  BreadcrumbComponent,
  LandingPageComponent,
  LandingHeroComponent,
  LandingGoalsComponent,
  LandingActivitiesComponent,
  LandingEducation21Component,
  LandingVideoComponent,
  LandingInternationalReportsComponent,
  LandingChanelsComponent,
  GoalsComponent,
  ActivityComponent,
  Education21Component,
  InternationalReportsComponent,
  VideoComponent,
  AppYoutubeZoomComponent,
];

registerLocaleData(ar);

@NgModule({
  declarations: [...directives, ...pipes, ...components, SingleActivityComponent],
  imports: [...angular, ...material, ...thirdParties, AppRoutingModule],
  providers: [
    ...pipes,
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphqlURI,
          }),
        };
      },
      deps: [HttpLink],
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: arEG,
    },
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ["l", "LL"],
        },
        display: {
          dateInput: "yyyy/MM/dd",
          monthYearLabel: "MMMM yyyy",
          monthLabel: "MMMM",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM yyyy",
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
