import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";

import {ReplaySubject, takeUntil} from "rxjs";
import {Activity} from "src/app/interfaces/landing-page";
import {CMSService} from "src/app/services/cms.service";
import {environment} from "src/environments/environment";

import {AppYoutubeZoomComponent} from "../../shared/app-youtube-zoom/app-youtube-zoom.component";
import {MatDialog} from "@angular/material/dialog";
import {Title} from "@angular/platform-browser";
import {PageData} from "src/app/interfaces/page-data";

@Component({
  selector: "app-activities",
  templateUrl: "./activities.component.html",
  styleUrls: ["./activities.component.scss"],
})
export class ActivityComponent implements OnInit {
  activities!: Activity[];
  activitiesTypes!: string[];
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private cmsService: CMSService,
    public dialog: MatDialog,
  ) {
    this.setPageTitle();
  }

  loading!: boolean;
  page = 0;
  unsubscribe$ = new ReplaySubject(1);
  type!: string[];
  totalDocuments!: number;
  activitiesLimit = environment.limit.activitiesLimit;
  paginationButtonsNumber!: number;

  ngOnInit(): void {
    this.activities = this.route.snapshot.data["page"].activities;
    this.activitiesTypes = this.route.snapshot.data["page"].activitiesTypes.map((type) => type.name);
    this.totalDocuments = this.route.snapshot.data["page"].paging[0].count.id;
    this.paginationButtonsNumber = Math.ceil(this.totalDocuments / this.activitiesLimit);
  }
  setPageTitle() {
    let route = this.route;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const {title} = route.snapshot.data["page"] as PageData;
    if (title) {
      this.titleService.setTitle(`${title} | أنشطة وفعاليات التعليم 2030`);
    } else {

      this.titleService.setTitle(`أنشطة وفعاليات | التعليم 2030`);
    }
  }

  switchType(e: Event) {
    this.activities = [];
    this.page = 0;
    if ((e.target as HTMLSelectElement).value != "") {
      this.getItemsPerSelectedType([(e.target as HTMLSelectElement).value]);
    } else {
      this.getItemsPerSelectedType(this.activitiesTypes);
    }
  }

  getItemsPerSelectedType(type: string[]) {
    this.type = type;
    this.loading = true;
    this.cmsService
      .getActivitiesByType(type, this.page)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result: any) => {
          this.totalDocuments = result.paging[0].count.id;
          this.paginationButtonsNumber = Math.ceil(this.totalDocuments / this.activitiesLimit);

          this.activities.push(...result.activities);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }
  // specific page
  showItemsByPage(page: number) {
    const listItems = document.getElementsByClassName("page-item");
    for (let i = 0; i < listItems.length; i++) {
      const item = listItems[i];
      if (page === i + 1) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    }
    if (!this.type) {
      this.type = this.activitiesTypes;
    }
    this.cmsService
      .getActivitiesByType(this.type, page)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result: any) => {
          this.totalDocuments = result.paging[0].count.id;
          this.activities = result.activities;
          this.loading = false;
          window.scrollTo(0, 0);
        },
        error: () => {
          this.loading = false;
        },
      });
  }


  openZoomDialog(youtubeVideoUrl): void {
    this.dialog.open(AppYoutubeZoomComponent, {
      data: {youtubeVideoUrl},
    });
  }
}
